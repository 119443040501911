var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-form',[_c('validation-observer',{ref:"websiteAddress"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Telefon 1","label-for":"sTelefon1"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Telefon 1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"sTelefon1","state":errors.length > 0 ? false : null},model:{value:(_vm.websiteAddress.sTelefon1),callback:function ($$v) {_vm.$set(_vm.websiteAddress, "sTelefon1", $$v)},expression:"websiteAddress.sTelefon1"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Telefon 2","label-for":"sTelefon2"}},[_c('validation-provider',{attrs:{"name":"Telefon 2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"sTelefon2","state":errors.length > 0 ? false : null},model:{value:(_vm.websiteAddress.sTelefon2),callback:function ($$v) {_vm.$set(_vm.websiteAddress, "sTelefon2", $$v)},expression:"websiteAddress.sTelefon2"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":"E-Posta 1","label-for":"sEPosta1"}},[_c('validation-provider',{attrs:{"rules":"required|email","name":"E-Posta 1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"sEPosta1","state":errors.length > 0 ? false : null},model:{value:(_vm.websiteAddress.sEPosta1),callback:function ($$v) {_vm.$set(_vm.websiteAddress, "sEPosta1", $$v)},expression:"websiteAddress.sEPosta1"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":"E-Posta 2","label-for":"sEPosta2"}},[_c('validation-provider',{attrs:{"rules":"email","name":"E-Posta 2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"sEPosta2","state":errors.length > 0 ? false : null},model:{value:(_vm.websiteAddress.sEPosta2),callback:function ($$v) {_vm.$set(_vm.websiteAddress, "sEPosta2", $$v)},expression:"websiteAddress.sEPosta2"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('b-form-group',{attrs:{"label":"Merkez Adresi"}},[_c('b-form-textarea',{attrs:{"id":"textarea-adresmerkez","placeholder":"Merkez Adresi","rows":"3"},model:{value:(_vm.websiteAddress.sAdresMerkez),callback:function ($$v) {_vm.$set(_vm.websiteAddress, "sAdresMerkez", $$v)},expression:"websiteAddress.sAdresMerkez"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Şube 1 Adresi"}},[_c('b-form-textarea',{attrs:{"id":"textarea-adresmsube1","placeholder":"Şube 1 Adresi","rows":"8"},model:{value:(_vm.websiteAddress.sAdresSube1),callback:function ($$v) {_vm.$set(_vm.websiteAddress, "sAdresSube1", $$v)},expression:"websiteAddress.sAdresSube1"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Şube 2 Adresi"}},[_c('b-form-textarea',{attrs:{"id":"textarea-adressube2","placeholder":"Şube 2 Adresi","rows":"8"},model:{value:(_vm.websiteAddress.sAdresSube2),callback:function ($$v) {_vm.$set(_vm.websiteAddress, "sAdresSube2", $$v)},expression:"websiteAddress.sAdresSube2"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('save-button',{attrs:{"onClickSave":_vm.saveWebsiteAddress,"showSaveAndClose":false}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }