<template>
  <b-card>
    <!-- form -->
    <b-form>
      <validation-observer ref="websiteAddress">
        <b-row>
          <b-col cols="12" md="6" lg="6">
            <b-form-group label="Telefon 1" label-for="sTelefon1">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Telefon 1"
              >
                <b-input-group>
                  <b-form-input
                    id="sTelefon1"
                    v-model="websiteAddress.sTelefon1"
                    :state="errors.length > 0 ? false : null"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="6">
            <b-form-group label="Telefon 2" label-for="sTelefon2">
              <validation-provider #default="{ errors }" name="Telefon 2">
                <b-input-group>
                  <b-form-input
                    id="sTelefon2"
                    v-model="websiteAddress.sTelefon2"
                    :state="errors.length > 0 ? false : null"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="6" lg="6">
            <b-form-group label="E-Posta 1" label-for="sEPosta1">
              <validation-provider
                #default="{ errors }"
                rules="required|email"
                name="E-Posta 1"
              >
                <b-input-group>
                  <b-form-input
                    id="sEPosta1"
                    v-model="websiteAddress.sEPosta1"
                    :state="errors.length > 0 ? false : null"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="6">
            <b-form-group label="E-Posta 2" label-for="sEPosta2">
              <validation-provider
                #default="{ errors }"
                rules="email"
                name="E-Posta 2"
              >
                <b-input-group>
                  <b-form-input
                    id="sEPosta2"
                    v-model="websiteAddress.sEPosta2"
                    :state="errors.length > 0 ? false : null"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col cols="12" md="12" lg="12">
            <b-form-group label="Merkez Adresi">
              <b-form-textarea
                id="textarea-adresmerkez"
                placeholder="Merkez Adresi"
                v-model="websiteAddress.sAdresMerkez"
                rows="3"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="6">
            <b-form-group label="Şube 1 Adresi">
              <b-form-textarea
                id="textarea-adresmsube1"
                placeholder="Şube 1 Adresi"
                v-model="websiteAddress.sAdresSube1"
                rows="8"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="6">
            <b-form-group label="Şube 2 Adresi">
              <b-form-textarea
                id="textarea-adressube2"
                placeholder="Şube 2 Adresi"
                v-model="websiteAddress.sAdresSube2"
                rows="8"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <save-button
              :onClickSave="saveWebsiteAddress"
              :showSaveAndClose="false"
            />
          </b-col>
        </b-row>
      </validation-observer>
    </b-form>
  </b-card>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import SaveButton from '@/components/SaveButton.vue'
import ApiService from '@/common/api.service'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email,
} from '@validations'
// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'
// Alert
import AlertService from '@/common/alert.service'

export default {
  components: {
    SaveButton,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      websiteAddress: {
        sAciklama: null,
        sEtiketler: null,
        sBaslik: null,
        sScript: null,
        sStyle: null,
      },
    }
  },
  methods: {
    fetchWebsiteAddress() {
      ApiService.get('manage/websiteaddress/get', {}, this)
        .then(response => {
          if (response.statusCode === 200) {
            this.websiteAddress = response.result;
          } else {
            AlertService.error(this, response.message);
          }
        })
    },

    saveWebsiteAddress() {
      this.$refs.websiteAddress.validate().then(success => {
        if (success) {
          ApiService.post('manage/websiteaddress/save', this.websiteAddress, this)
            .then(response => {
              if (response.statusCode === 200) {
                this.$toast({
                  component: ToastificationSuccess,
                  position: 'top-right',
                  props: { text: response.message },
                })
              } else {
                AlertService.error(this, response.message)
              }
            })
        }
      })
    },
  },

  mounted() {
    this.fetchWebsiteAddress();
  },
}
</script>
